<template>
  <div class="register-comp">
    <div class="left">
      <h1 class="title mont">{{ blok.Title }}</h1>
      <div
        v-html="$storyapi.richTextResolver.render(blok.Description)"
        class="desc"
      />
    </div>
    <transition name="dropdown" mode="out-in">
      <div class="right" v-if="!success" key="right">
        <h2 class="title font-mont">{{ $t("Dealer registration form") }}</h2>
        <div class="registration-from">
          <ValidationObserver key="log-in" v-slot="{ validate }" ref="observer">
            <h3 class="section-title mont">
              {{ $t("Main address details") }}
            </h3>
            <div class="double-input" style="margin-bottom: 0">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <InputSelect
                  class="my-2 mx-auto"
                  v-model="form.country"
                  id="select"
                  :name="'country'"
                  :label="$t('Country')"
                  :optionsData="countries"
                  :value.sync="form.country"
                  :optionToDisplay="'full_name_locale'"
                  :optionToSave="'two_letter_abbreviation'"
                  :required="true"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  @optionUpdated="getCountryInfo"
                />
              </ValidationProvider>
              <!-- Client wanted state removed for eu shop -->
              <!-- <ValidationProvider
                v-slot="{ errors }"
                :rules="regions.length > 0 ? 'required' : ''"
              >
                <InputSelect
                  class="my-2 mx-auto"
                  v-model="form.state"
                  id="select"
                  :name="'state'"
                  :label="$t('State')"
                  :optionsData="regions"
                  :value.sync="form.state"
                  :optionToDisplay="'name'"
                  :optionToSave="'region'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  :required="regions.length > 0"
                />
              </ValidationProvider> -->
              <ValidationProvider v-slot="{ errors }" rules="required">
                <CustomInput
                  v-model="form.city"
                  v-e2e="'city'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="city"
                  :label="$t('City')"
                  class="form__element custom-input"
                  :required="true"
                />
              </ValidationProvider>
            </div>
            <div class="double-input">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <CustomInput
                  v-model="form.street"
                  v-e2e="'street'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="street"
                  :label="$t('Street name')"
                  class="form__element custom-input"
                  :required="true"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" rules="required">
                <CustomInput
                  v-model="form.street_number"
                  v-e2e="'street_number'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="street_number"
                  :label="$t('Number')"
                  class="form__element custom-input"
                  :required="true"
                />
              </ValidationProvider>
            </div>
            <div class="double-input">
              <ValidationProvider v-slot="{ errors }" rules="required|zip">
                <CustomInput
                  v-model="form.zip"
                  v-e2e="'zip'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="zip"
                  :label="$t('Zip code')"
                  class="form__element custom-input"
                  :style="{ maxWidth: '50%' }"
                  :required="true"
                />
              </ValidationProvider>
              <!-- <ValidationProvider v-slot="{ errors }" rules="required">
                <CustomInput
                  v-model="form.city"
                  v-e2e="'city'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="city"
                  :label="$t('City')"
                  class="form__element custom-input"
                  :required="true"
                />
              </ValidationProvider> -->
            </div>
            <h3 class="section-title font-mont">
              {{ $t("Company details") }}
            </h3>
            <div class="single-input">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <CustomInput
                  v-model="form.company_name"
                  v-e2e="'company_name'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="company_name"
                  :label="$t('Company name')"
                  class="form__element custom-input"
                  :required="true"
                />
              </ValidationProvider>
            </div>
            <div class="double-input">
              <ValidationProvider
                v-slot="{ errors }"
                rules="max:20"
                v-if="form.country === 'NL'"
              >
                <CustomInput
                  v-model="form.coc"
                  v-e2e="'coc'"
                  name="coc"
                  :label="$t('Chamber of Commerce nr.')"
                  class="form__element custom-input"
                  :required="false"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" rules="required|max:20">
                <CustomInput
                  v-model="form.vat"
                  v-e2e="'vat'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="vat"
                  :label="$t('VAT registration nr.')"
                  class="form__element custom-input"
                  :required="true"
                />
              </ValidationProvider>
            </div>
            <div class="double-input">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <InputSelect
                  v-if="company_types"
                  class="my-2 mx-auto"
                  v-model="form.company_type"
                  id="select"
                  :name="'company_type'"
                  :label="$t('Company Type')"
                  :optionsData="company_types"
                  :value.sync="form.company_type"
                  :optionToDisplay="'type_value'"
                  :optionToSave="'type_value'"
                  :required="true"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  :rules="{ required: { allowFalse: false } }"
                />
              </ValidationProvider>
              <ValidationProvider>
                <CustomInput
                  v-model="form.website"
                  v-e2e="'website'"
                  name="website"
                  :label="$t('Website')"
                  class="form__element custom-input"
                />
              </ValidationProvider>
            </div>
            <h3 class="section-title mont">
              {{ $t("Personal details") }}
            </h3>
            <div class="radio-input">
              <span class="font-fgb">{{ $t("Gender") }} *</span>
              <div class="radio-combo">
                <input
                  type="radio"
                  name="gender-list"
                  id="male"
                  value="1"
                  v-model="form.gender"
                />
                <label for="male" class="font-fgb">{{ $t("Male") }}</label>
              </div>
              <div class="radio-combo">
                <input
                  type="radio"
                  name="gender-list"
                  id="female"
                  value="2"
                  v-model="form.gender"
                />
                <label for="female" class="font-fgb">{{ $t("Female") }}</label>
              </div>
              <div class="radio-combo">
                <input
                  type="radio"
                  name="gender-list"
                  id="not_specified"
                  value="3"
                  v-model="form.gender"
                />
                <label for="not_specified" class="font-fgb">{{
                  $t("Non-binary")
                }}</label>
              </div>
            </div>
            <div class="double-input">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <CustomInput
                  v-model="form.first_name"
                  v-e2e="'first_name'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="first_name"
                  :label="$t('First name')"
                  class="form__element custom-input"
                  :required="true"
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" rules="required">
                <CustomInput
                  v-model="form.last_name"
                  v-e2e="'last_name'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="last_name"
                  :label="$t('Last name')"
                  class="form__element custom-input"
                  :required="true"
                />
              </ValidationProvider>
            </div>
            <div class="single-input">
              <ValidationProvider v-slot="{ errors }" rules="required|phone">
                <CustomInput
                  v-model="form.phone"
                  v-e2e="'phone'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="phone"
                  :label="$t('Phone number')"
                  class="form__element custom-input"
                  :required="true"
                />
              </ValidationProvider>
            </div>
            <h3 class="section-title mont">
              {{ $t("Account preferences") }}
            </h3>
            <div class="single-input">
              <ValidationProvider v-slot="{ errors }" rules="required|email">
                <CustomInput
                  v-model="form.email"
                  v-e2e="'reg-modal-email'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="email"
                  :label="$t('Email address')"
                  class="form__element custom-input"
                  :required="true"
                />
              </ValidationProvider>
            </div>
            <div class="double-input">
              <ValidationProvider v-slot="{ errors }" rules="required|password">
                <CustomInput
                  v-model="form.password"
                  v-e2e="'reg-modal-password'"
                  :valid="!errors[0]"
                  :error-message="$t(errors[0])"
                  name="password"
                  :label="$t('Password')"
                  type="password"
                  has-show-password
                  class="form__element custom-input"
                  :required="true"
                  @input="
                    form.password_confirm.length > 0 ? checkValidPass() : ''
                  "
                />
              </ValidationProvider>
              <ValidationProvider rules="required">
                <CustomInput
                  v-model="form.password_confirm"
                  v-e2e="'reg-modal-password'"
                  :valid="validPass"
                  :error-message="$t('Password does not match')"
                  name="password"
                  :label="$t('Confirm Password')"
                  type="password"
                  has-show-password
                  class="form__element custom-input"
                  :required="true"
                  @input="checkValidPass()"
                />
              </ValidationProvider>
            </div>
            <div class="checkbox-input">
              <input
                checked
                id="checked-checkbox"
                type="checkbox"
                v-model="form.newsletter"
              />
              <label
                for="checked-checkbox"
                class="ml-2 text-sm font-medium font-fgb text-gray-900 dark:text-gray-300"
                >{{
                  $t(
                    "Send me personalized offers and other information from Shots in the future."
                  )
                }}</label
              >
            </div>
            <button
              class="btn"
              @click.prevent="validate().then(() => registerCustomer())"
            >
              {{ $t("Apply for a dealer account") }}
            </button>
          </ValidationObserver>
        </div>
      </div>
      <div class="success" v-else key="success">
        <div class="icon">
          <Icon height="150" icon="mdi:account-success" />
        </div>
        <div
          v-html="$storyapi.richTextResolver.render(blok.success_message)"
          class="message"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import CustomInput from "~/components/Custom/CustomInput.vue";
import InputSelect from "~/components/Custom/InputSelect.vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email, max } from "vee-validate/dist/rules";
import { ref, onMounted } from "@nuxtjs/composition-api";
import { useCountrySearch } from "~/composables";
import useCompanyType from "~/composables/useCompanyType";
import useRegisterCustomer from "~/composables/useRegisterCustomer";
import { useUiNotification } from "~/composables";
import { Icon } from "@iconify/vue2";

extend("email", {
  ...email,
  message: "Invalid email",
});

extend("required", {
  ...required,
  message: "This field is required",
});
extend("max", {
  ...max,
  message: "Max char length exceeded",
});

const customerPasswordRegExp = /^(?=.*[A-Z])(?=.*\d).+$/;

extend("password", {
  message: "Please use one uppercase and one number in your password.",
  validate: (value) => customerPasswordRegExp.test(value),
});

const customZipReg =
  /^(?:\d{4}|\d{5}|\d{4} [A-Za-z]{2}|\d{5}[A-Za-z]{2}|\d{3}-\d{4}|\d{2}-\d{3}|\d{4}-\d{3}|\d{6}|[A-Za-z]\d|[A-Za-z]\d{2}|[A-Za-z]{2}\d|[A-Za-z]{2}\d{2}|[A-Za-z]\d[A-Za-z]|[A-Za-z]{2}\d[A-Za-z]|[A-Za-z]{3})$/;

extend("zip", {
  message: "Please use valid zip code",
  validate: (value) => customZipReg.test(value),
});

const customPhoneReg = /^\+\d{8,}$/;

extend("phone", {
  message: "Please use +11234567890 for phone number format",
  validate: (value) => customPhoneReg.test(value),
});

export default {
  components: {
    CustomInput,
    InputSelect,
    ValidationProvider,
    ValidationObserver,
    Icon,
  },
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup() {
    const form = ref({
      email: "",
      password: "",
      password_confirm: "",
      company_name: "",
      coc: "",
      vat: "",
      company_type: "",
      website: "",
      country: "",
      state: {},
      street: "",
      street_number: "",
      city: "",
      zip: "",
      gender: "3",
      first_name: "",
      last_name: "",
      phone: "",
      newsletter: false,
    });

    const { load: loadCountries, search: searchCountry } = useCountrySearch();
    const { getData: getCompanyTypes } = useCompanyType();
    const { register } = useRegisterCustomer();
    const { send: sendNotification } = useUiNotification();
    const countries = ref([]);
    const regions = ref([]);
    const company_types = ref([]);
    const observer = ref(null);
    const success = ref(false);

    const getCountryInfo = async () => {
      form.value.state = {};
      await searchCountry({ id: form.value.country }).then((res) => {
        regions.value = res.available_regions || [];
      });
    };

    const validPass = ref(true);
    const checkValidPass = () => {
      if (form.value.password !== form.value.password_confirm) {
        validPass.value = false;
      } else {
        validPass.value = true;
      }
    };

    const validateFields = async () => {
      return observer.value.validate();
    };

    const registerCustomer = async () => {
      if (!validPass.value || form.value.password_confirm.length === 0) {
        validPass.value = false;
        return;
      }
      await validateFields().then(async (res) => {
        if (res) {
          await register(form.value).then((res) => {
            if (res && res.errors) {
              res.errors.forEach((e) => {
                sendNotification({
                  id: Symbol("no-customer-created"),
                  message: $nuxt.$t(e.message),
                  type: "danger",
                  icon: "error",
                  persist: false,
                  title: "Error",
                });
              });
            } else if (res) {
              success.value = true;
              document.body.scrollIntoView({ behavior: "smooth" });
            }
          });
        }
      });
    };

    onMounted(() => {
      loadCountries().then((res) => {
        countries.value = res.sort((a, b) =>
          a.full_name_locale
            .toLowerCase()
            .localeCompare(b.full_name_locale.toLowerCase())
        );
      });
      getCompanyTypes().then((res) => {
        company_types.value = res;
      });
    });

    return {
      form,
      validPass,
      checkValidPass,
      countries,
      regions,
      company_types,
      getCountryInfo,
      registerCustomer,
      observer,
      success,
    };
  },
};
</script>

<style lang="scss" scoped>
.register-comp {
  @apply flex justify-around py-16 relative;

  .left,
  .right {
    .title {
      @apply text-2xl font-bold mb-6;
    }
  }
  .desc {
    ::v-deep p {
      @apply font-fgb;
    }
  }
  .left {
    max-width: 550px;
  }

  .right {
    min-width: 600px;
    .section-title {
      @apply mb-6 text-lg;
    }
    .section-title:not(:first-child) {
      @apply mt-6;
    }
    .single-input {
      @apply mb-2;
      max-width: 80%;
    }
    .double-input {
      @apply flex justify-between gap-2 mb-2;
      span {
        width: 100%;
      }
    }
    .radio-input {
      @apply flex gap-4 text-base my-4 ml-4;
      span {
        color: #655a5a;
      }
      .radio-combo {
        @apply flex gap-2 items-start;
        label {
          @apply text-base text-black;
        }
        input {
          border-color: #655a5a;
          accent-color: #e30613;
          width: 20px;
          height: 20px;
        }
      }
    }
    .btn {
      @apply mt-4;
    }
  }

  .success {
    @apply flex items-center justify-center flex-col;
    max-width: 500px;
    .icon {
      .iconify {
        @apply text-green;
      }
    }
    .message {
      @apply text-black text-lg font-fgb;
    }
  }
  @media only screen and (max-width: 1024px) {
    @apply flex-wrap flex-col;
    .right {
      min-width: 200px;
      .title {
        @apply mt-4;
      }
      .double-input {
        @apply flex-col;
        max-width: 80%;
      }
    }
  }
}
</style>
