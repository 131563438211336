<template>
  <div class="simple-page-comp">
    <div class="header">
      <h1 class="title container">{{ blok.title }}</h1>
    </div>

    <div
      class="content container"
      v-html="$storyapi.richTextResolver.render(blok.content)"
    ></div>
  </div>
</template>
<script>
export default {
  name: "SimplePageComponent",
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
};
</script>

<style lang="scss" scoped>
.simple-page-comp {
  .header {
    @apply py-24 px-16;
    background: #3c3c3c;
    .title {
      @apply font-mont font-bold text-5xl text-white;
    }
  }
  ::v-deep .content {
    @apply py-8;
    p {
      @apply py-2 text-base;
    }
    img {
      @apply py-2;
      width: 500px;
    }
  }
}
</style>
